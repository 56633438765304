import axios from 'axios';
import firebase from '../firebase';
const storage = firebase.storage().ref();

export const GET_CATEGORIES = {
    request: 'GET_CATEGORIES_REQUEST',
    success: 'GET_CATEGORIES_SUCCESS',
    failure: 'GET_CATEGORIES_FAILURE'
}

export const getCategories = () => dispatch => {
    dispatch({ type: GET_CATEGORIES.request });
    axios.get("https://us-central1-clothes-shoes-and-toys.cloudfunctions.net/getCategories")
        .then(response => {
            dispatch({
                type: GET_CATEGORIES.success,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: GET_CATEGORIES.failure,
                error
            })
        });
}

export const GET_IMAGE_URL = {
    request: 'GET_IMAGE_URL_REQUEST',
    success: 'GET_IMAGE_URL_SUCCESS',
    failure: 'GET_IMAGE_URL_FAILURE'
}

export const getImageURL = (path) => {
    return dispatch => {
        dispatch({ type: GET_IMAGE_URL.request, path });
        storage.child('public/images/' + path).getDownloadURL()
            .then(imageURL => {
                // Insert url into an <img> tag to "download"
                dispatch({
                    type: GET_IMAGE_URL.success,
                    imageURL,
                    path
                })
            }).catch(error => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;

                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;

                    case 'storage/canceled':
                        // User canceled the upload
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                    default:
                        break;
                }
                dispatch({
                    type: GET_IMAGE_URL.failure,
                    error,
                    path
                })
            });
    }
}

export const SET_IMAGE_URL = {
    request: 'SET_IMAGE_URL_REQUEST',
    success: 'SET_IMAGE_URL_SUCCESS',
    failure: 'SET_IMAGE_URL_FAILURE'
}

export const setImageURL = (path) => {
    return dispatch => {
        dispatch({ type: SET_IMAGE_URL.request, payload: { path } });
        storage.child('public/images/' + path).getDownloadURL()
            .then(imageURL => {
                // Insert url into an <img> tag to "download"
                dispatch({
                    type: GET_IMAGE_URL.success,
                    payload: { imageURL, path }
                })
            }).catch(error => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;

                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;

                    case 'storage/canceled':
                        // User canceled the upload
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                    default:
                        break;
                }
                dispatch({
                    type: GET_IMAGE_URL.failure,
                    error: { error, path }
                })
            });
    }
}