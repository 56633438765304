// import axios from 'axios';

import { log } from '../firebase';

export const GET_FAVORITES_LS = {
    request: 'GET_FAVORITES_LS_REQUEST',
    success: 'GET_FAVORITES_LS_SUCCESS',
    failure: 'GET_FAVORITES_LS_FAILURE'
}

export const getFavoritesFromLocalStorage = () => dispatch => {
    var favorites = localStorage.getItem('favorites');
    if(favorites){
        if(favorites.substring(0,1)==='['){
            favorites = JSON.parse(favorites)
            dispatch({ type: GET_FAVORITES_LS.success, favorites });
        } else {
            localStorage.removeItem('favorites')
        }
    } else {
        // it could have also not been set, not a failure
        dispatch({ type: GET_FAVORITES_LS.success, favorites: [] });
    }
}

export const SET_FAVORITES_LS = {
    request: 'SET_FAVORITES_LS_REQUEST',
    success: 'SET_FAVORITES_LS_SUCCESS',
    failure: 'SET_FAVORITES_LS_FAILURE'
}

export const setFavoritesInLocalStorage = favorites => dispatch => {
    localStorage.setItem('favorites', JSON.stringify(favorites));
    dispatch({ type: SET_FAVORITES_LS.success, favorites });
}

export const ADD_FAVORITE = {
    request: 'ADD_FAVORITE_REQUEST',
    success: 'ADD_FAVORITE_SUCCESS',
    failure: 'ADD_FAVORITE_FAILURE'
}

export const addFavorite = (id,user) => dispatch => {
    log('add_favorite', {id});
    dispatch({ type: ADD_FAVORITE.request, id });
    //if(!user){
        const favorites = JSON.parse(localStorage.getItem('favorites'));
        var newFavorites = [];
        if(favorites && Array.isArray(favorites)){
            newFavorites = [...favorites,id]
        } else {
            newFavorites = [id]
        }
        localStorage.setItem('favorites', JSON.stringify(newFavorites))
    //}
    dispatch({ type: ADD_FAVORITE.success, id });
    /*
    axios.get("https://us-central1-clothes-shoes-and-toys.cloudfunctions.net/getProducts")
        .then(response => {
            dispatch({
                type: SET_FAVORITE.success,
                products: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: SET_FAVORITE.failure,
                error
            })
        });
        */
}

export const REMOVE_FAVORITE = {
    request: 'REMOVE_FAVORITE_REQUEST',
    success: 'REMOVE_FAVORITE_SUCCESS',
    failure: 'REMOVE_FAVORITE_FAILURE'
}

export const removeFavorite = (id,user) => dispatch => {
    log('remove_favorite', {id});
    dispatch({ type: REMOVE_FAVORITE.request, id });
    //if(!user){
        const favorites = JSON.parse(localStorage.getItem('favorites'));
        const newFavorites = [...favorites.filter(f=>f!==id)]
        localStorage.setItem('favorites', JSON.stringify(newFavorites))
    //}
    dispatch({ type: REMOVE_FAVORITE.success, id });
}