import firebase from '../firebase';

export const SET_USER = 'SET_USER'

export const setUser = user => dispatch => {
    dispatch({type: SET_USER, user})
}

export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS'

export const clearAuthErrors = () => dispatch => {
    dispatch({type: CLEAR_AUTH_ERRORS})
}

export const SIGNUP = {
    request: 'SIGNUP_REQUEST',
    success: 'SIGNUP_SUCCESS',
    failure: 'SIGNUP_FAILURE'
}

export const signupUser = (email, password) => dispatch => {
    dispatch({type: SIGNUP.request});
    firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(user => {
            dispatch({
                type: SIGNUP.success,
                payload: user
            });
            verifyEmail(user)
        })
        .catch(error => {
            dispatch({
                type: SIGNUP.failure,
                error
            });
        });
};

export const VERIFY_EMAIL = {
    request: 'VERIFY_EMAIL_REQUEST',
    success: 'VERIFY_EMAIL_SUCCESS',
    failure: 'VERIFY_EMAIL_FAILURE'
}

export const verifyEmail = user => dispatch => {
    dispatch({type: VERIFY_EMAIL.request});
    user.sendEmailVerification
    .then(response => {
        dispatch({
            type: VERIFY_EMAIL.success,
            payload: response
        });
    })
    .catch(error => {
        dispatch({
            type: VERIFY_EMAIL.failure,
            error
        });
    });
};

export const LOGIN = {
    request: 'LOGIN_REQUEST',
    success: 'LOGIN_SUCCESS',
    failure: 'LOGIN_FAILURE'
}

export const loginUser = (email, password) => dispatch => {
    dispatch({type: LOGIN.request});
    firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(user => {
            dispatch({
                type: LOGIN.success,
                user
            });
        })
        .catch(error => {
            dispatch({
                type: LOGIN.failure,
                error
            });
        });
};

export const LOGOUT = {
    request: 'LOGOUT_REQUEST',
    success: 'LOGOUT_SUCCESS',
    failure: 'LOGOUT_FAILURE'
}

export const logoutUser = () => dispatch => {
    dispatch({type: LOGOUT.request});
    firebase
        .auth()
        .signOut()
        .then(() => {
            dispatch({type: LOGOUT.success});
        })
        .catch(error => {
            dispatch({
                type: LOGOUT.failure,
                error
            });
        });
};

export const VERIFY_USER = {
    request: 'VERIFY_USER_REQUEST',
    success: 'VERIFY_USER_SUCCESS'
}

export const verifyAuth = () => dispatch => {
    dispatch({type: VERIFY_USER.request});
    firebase
        .auth()
        .onAuthStateChanged(user => {
            if (user !== null) {
                dispatch({
                    type: LOGIN.success,
                    user
                });
            }
            dispatch({type: VERIFY_USER.success});
        });
};