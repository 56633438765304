import axios from 'axios';

export const GET_SUBCATEGORIES = {
    request: 'GET_SUBCATEGORIES_REQUEST',
    success: 'GET_SUBCATEGORIES_SUCCESS',
    failure: 'GET_SUBCATEGORIES_FAILURE'
}

export const getSubCategories = () => dispatch => {
    dispatch({ type: GET_SUBCATEGORIES.request });
    axios.get("https://us-central1-clothes-shoes-and-toys.cloudfunctions.net/getSubCategory")
        .then(response => {
            dispatch({
                type: GET_SUBCATEGORIES.success,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: GET_SUBCATEGORIES.failure,
                error
            })
        });
}
