import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
       /* flexGrow: 1,*/
        /*padding: '2rem'*/
    },
    center: {
        textAlign: 'center'
    },
    itemsContainer: {
        padding:'2rem',
        backgroundColor: 'lightgrey'
    },
    message: {
        maxWidth: '40rem',
        margin: 'auto',
        paddingBottom: '2rem',
        fontSize: '1.1rem',
        // lineHeight: '1.6rem'
    }
}));

const Faq = props => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container justify='center' alignItems='flex-start'>
                <Grid item xs={12}>
                    <Link to='/'>Continue Shopping</Link>
                </Grid>
                <Grid item xs={12} className={classes.center}>
                    <Typography>Top Products from Your Favorite Stores</Typography>
                    <Typography className={classes.message}>Everyone's favorite children's products in one spot. Use our links to shop at your favorite stores.</Typography>
                </Grid>
                <Grid item xs={12} className={classes.center}>
                    <Typography>Some Frequently Asked Questions</Typography>
                    <Typography className={classes.message}>Some Answers</Typography>
                </Grid>
            </Grid>
        </div>
    );
}

function mapStateToProps(state) {
    var categories = [];
    if (state && state.categories && Array.isArray(state.categories)) {
        categories = state.categories
    }
    return {
        categories
    }
}

export default connect(mapStateToProps, {})(Faq);
