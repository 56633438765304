import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import CategoryList from './Category/CategoryList';
import Banner from './Banner';

const useStyles = makeStyles((theme) => ({
    headerContainer: {
        textAlign: 'center',
        padding: '1rem 2rem'
    },
    title1: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem',
        },
    },
    itemsContainer: {
        padding: '.25rem',
        backgroundColor: 'rgba(236, 236, 236, 1)'
    }
}));

const HomePage = () => {
    const classes = useStyles();
    return (
        <Grid container justify='center' alignItems='flex-start' style={{minHeight:'80vh'}}>
            <Banner
                headline="Fall in Love with Mommy Fashion"
                message="Find amazingly comfortable dresses under $25 at your favorite stores!"
                image="maternity-dress-stripes.png"
                imageDescription="Pregnant mother wearing striped maternity dress"
                path='mom-fashion'
                linkText='Shop Clothes for Mom'
            />
            <Grid container item justify="center" xs={12} className={classes.itemsContainer}>
                <CategoryList />
            </Grid>
        </Grid>
    );
}

export default HomePage;

/*
<Grid item xs={12} className={classes.headerContainer}>
                <Typography className={classes.title1} variant="body2" component="h1">The Best Products from Your Favorite Stores</Typography>
            </Grid>
            */