import { GET_PRODUCTS } from '../actions'

const initialState = {    
    status: 'NONE', // [NONE, REQUESTING, ERROR, SUCCESS]
    error: null,
    lastLoaded: null,
    products: []
}

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case GET_PRODUCTS.request:
            return {
                ...state,
                status: 'REQUESTING',
                error: null,
            };
        case GET_PRODUCTS.success:
            return {
                ...state,
                status: 'SUCCESS',
                error: null,
                lastLoaded: Date.now(),
                products: action.products
            };
        case GET_PRODUCTS.failure:
            return {
                ...state,
                error: action.error,
                status: 'ERROR'
            };
        default:
            return state;
    }
}

export default reducer;
