import React, { useEffect } from 'react';
import { log } from '../../firebase';

const Terms = () => {
    useEffect(() => {
        var s = document.createElement("script");
        let tag = document.getElementsByTagName("script")[0];

        s.src="https://cdn.iubenda.com/iubenda.js";

        tag.parentNode.insertBefore(s,tag);
    }, []);

    return <a onClick={() => log('terms_clicked')} href={`https://www.iubenda.com/terms-and-conditions/28183052`} className="iubenda-white no-brand iubenda-embed" title="Terms and Conditions ">Terms and Conditions</a>

}

export default Terms;

/*
<a href="https://www.iubenda.com/terms-and-conditions/28183052" class="iubenda-white no-brand iubenda-embed" title="Terms and Conditions ">Terms and Conditions</a>
<script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
*/