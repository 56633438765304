import React from 'react';
import { connect } from 'react-redux';

import FavoriteIcon from '@material-ui/icons/Favorite';

import FavoriteIconOutlined from '@material-ui/icons/FavoriteBorderOutlined';
import IconButton from '@material-ui/core/IconButton';

import { addFavorite, removeFavorite } from '../../actions'

const FavoriteButton = props => {
    const { productId, isFavorite, addFavorite, removeFavorite, user } = props;

    if (isFavorite) {
        return (
            <IconButton aria-label="remove from favorites" onClick={() => removeFavorite(productId, user)}>
                <FavoriteIcon style={{fill:'rgba(239,204,225,1.0)'}} />
            </IconButton>
        )
    }

    return (
        <IconButton aria-label="add to favorites" onClick={() => addFavorite(productId, user)} style={{border: '1px solid lightgrey'}}>
            <FavoriteIconOutlined style={{fill:'grey'}} />
        </IconButton>
    )
}

function mapStateToProps(state, ownProps) {
    return {
        isFavorite: state.favorites.favorites.includes(ownProps.productId),
        user: state.auth.user
    }
}

export default connect(mapStateToProps, { addFavorite, removeFavorite })(FavoriteButton);