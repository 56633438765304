import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Design from '../Design';

import Copyright from '../Copyright';

import { loginUser, clearAuthErrors } from '../../actions';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: Design.mainColor // theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: Design.mainColor,
        color: 'white'
    },
    links: {
        textDecoration:'none'
    }
}));

const Login = props => {
    const classes = useStyles();
    const { loginUser, auth, clearAuthErrors } = props;
    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit', // onBlur causes issues not being able to click on a link
        reValidateMode: 'onChange'
    });

    useEffect(() => {
        const { clearAuthErrors } = props;
        // Specify how to clean up after this effect:
        return () => {
            clearAuthErrors()
        };
      }, [] ); // use empty array as 2nd argument to make sure this only fires once and not on re-render

    const onSubmit = data => {
        loginUser(data.email, data.password)
    }

    const formFieldFocused = () => {
        if(auth.loginError){
            clearAuthErrors();
        }
    }

    return (
        <>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">Login</Typography>

            <form onSubmit={handleSubmit(onSubmit)} className={classes.form} noValidate>

                <TextField
                    variant="outlined"
                    margin="normal"
                    onFocus={formFieldFocused}
                    inputRef={register({
                        required: "Email is required.",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address."
                        }
                    })}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={errors.email ? true : false}
                    helperText={errors.email ? errors.email.message : null}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    onFocus={formFieldFocused}
                    inputRef={register({
                        required: "Password is required.",
                        pattern: {
                            value: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/i,
                            message: "Password should be at least 8 characters and contain at least one letter and at least one number."
                        }
                    })}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={errors.password ? true : false}
                    helperText={errors.password ? errors.password.message : null}
                />
                {true ? null : 
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                }

                {auth.loginError ? <p style={{color:'red'}}>The email or password that you provided does not match any account. Please try again or signup for an account.</p> : null}
                
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                >LOGIN</Button>

                <Grid container>
                    <Grid item xs>
                        <Link to="/auth/forgot-password" className="auth-links">
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to="/auth/signup" className="auth-links">
                            Don't have an account? Sign Up
                        </Link>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </form>
        </>
    );
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { loginUser, clearAuthErrors })(Login);