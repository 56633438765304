// import * as firebase from 'firebase'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';


const config = {
    apiKey: "AIzaSyAzxVHmMb3PDq7Str6ElNR3ohXVVRcVXsc",
    authDomain: "clothes-shoes-and-toys.firebaseapp.com",
    databaseURL: "https://clothes-shoes-and-toys.firebaseio.com",
    projectId: "clothes-shoes-and-toys",
    storageBucket: "clothes-shoes-and-toys.appspot.com",
    messagingSenderId: "1067745642939",
    appId: "1:1067745642939:web:cbd2a0835caecfbb61dc9e",
    measurementId: "G-6LXYLL1YHT"
};

export const log = (name, params = null) => {
    if(params){
        firebase.analytics().logEvent(name, params)
    } else {
        firebase.analytics().logEvent(name)
    }
}

export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

/*
const initialize = () => {
    let app = firebase.initializeApp(config);
    firebase.analytics()
    return app
}
*/
// firebase.initializeApp(firebaseConfig)
// console.log("firebase.apps.length: ", firebase.apps.length)

/*
export const logSimple = name => {
    firebase.analytics().logEvent(name)
}

export const logParams = (name, params) => {
    firebase.analytics().logEvent(name, params)
}
*/

// export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

// export const analytics = firebase.apps.length ? firebase.analytics() : null;