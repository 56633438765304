import { combineReducers } from 'redux';

import auth from './auth.reducer';
import categories from './category.reducer'
import favorites from './favorite.reducer';
import filters from './filter.reducer';
import products from './product.reducer'
import settings from './setting.reducer';
import stores from './store.reducer';
import subcategories from './subcategory.reducer';
import images from './image.reducer';

const rootReducer = combineReducers({
    auth,
    categories,
    favorites,
    filters,
    products,
    settings,
    stores,
    subcategories,
    images
});

export default rootReducer;