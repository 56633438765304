import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import firebase from '../firebase';

import AuthPage from './Auth/AuthPage';
import Faq from './Faq';
import FavoritesPage from './Product/FavoritesPage';
import HomePage from './HomePage'
// import ProductPage from './Product/ProductPage';
import SearchAppBar from './SearchAppBar'
import SideBar from './SideBar';
import Footer from './Footer';

import NewProductPage from './Product/NewProductPage';

import { setUser, getCategories, getProducts, getStores, getSubCategories, getFavoritesFromLocalStorage } from '../actions';

const Main = props => {

    useEffect(() => {
        const { categories, stores, subcategories, products } = props;
        const { getCategories, getFavoritesFromLocalStorage, getProducts, getStores, getSubCategories } = props;
        const { auth, setUser } = props; 
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                if (!auth.user) {
                    setUser(user)
                }
            } else {
                if (auth.user) {
                    setUser(null)
                }
            }
        });
        if (!categories.loaded && !categories.loading && !categories.error) {
            getCategories();
            getFavoritesFromLocalStorage();
        }
        if (!stores.loaded && !stores.loading && !stores.error) {
            getStores();
        }
        if (!subcategories.loaded && !subcategories.loading && !subcategories.error) {
            getSubCategories();
        }
        if(products.status === 'NONE'){
            getProducts();
        }
    }, []);

    /*
      const routes = categories.map(category => {
        return (
          <Route path={`/:${category.link}`} component={CategoryDetails} />
        )
      })
    */
    return (
        <>
            <SearchAppBar />
            <SideBar />
            <Switch>
                <Route path="/auth" component={AuthPage} />
                <Route path="/favorites" component={FavoritesPage} />
                <Route path="/learn-more" component={Faq} />
                <Route path="/:category" component={NewProductPage} />
                <Route path="/" component={HomePage} />
            </Switch>
            <Footer />
        </>
    );
}


function mapStateToProps(state) {
    return {
        categories: state.categories,
        stores: state.stores,
        auth: state.auth,
        products: state.products,
        subcategories: state.subcategories
    }
}

export default connect(mapStateToProps, { setUser, getCategories, getProducts, getStores, getSubCategories, getFavoritesFromLocalStorage })(Main);

/*
firebase.auth().onAuthStateChanged(function(user) {
console.log('auth change in main, user is: ', user)
if (user) {
    userLoggedIn(user);
    // User is signed in.

    var displayName = user.displayName;
    var email = user.email;
    var emailVerified = user.emailVerified;
    var photoURL = user.photoURL;
    var isAnonymous = user.isAnonymous;
    var uid = user.uid;
    var providerData = user.providerData;

    // ...
} else {
    userLoggedOut()
    // User is signed out.
    // ...
}
});
*/