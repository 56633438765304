import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { toggleSidebar } from '../actions';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import { sortCategories } from '../common'

function HomeIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </SvgIcon>
    );
  }

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    link: {
        color: 'inherit',
        textDecoration: 'none'
    }
});

const SideBar = props => {

    const { showSideBar, categories, toggleSidebar } = props;

    const toggleDrawer = (text) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        toggleSidebar(text)
    };

    const classes = useStyles();

    const list = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer('onClick')}
            onKeyDown={toggleDrawer('onKeyDown')}
        >
            <List>
                    <Link className={classes.link} to={'/'}>
                        <ListItem button >
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                            <ListItemText primary='Home' />
                        </ListItem>
                    </Link>
            </List>
            <Divider />
            <List>
                {categories.map((category, index) => (
                    <Link key={category.name} className={classes.link} to={`/${category.link}`}>
                        <ListItem button >
                            <ListItemText primary={category.name} />
                        </ListItem>
                    </Link>
                ))}
            </List>
        </div>
    );

    return (
        <div>
            <Drawer anchor={'left'} open={showSideBar} onClose={toggleDrawer('onClose')}>
                {list()}
            </Drawer>
        </div>
    );
}

function mapStateToProps(state) {

    let categories = []
    let products = []
    let sub_categories = []

    if (state.products.products && Array.isArray(state.products.products)) {
        products = state.products.products
    }

    products.forEach(p=>{
        if(p.sub_category){
            if(!sub_categories.includes(p.sub_category)){
                sub_categories.push(p.sub_category)
            }
        }
    })

    if (state && state.categories) {
        if(state.categories.categories && Array.isArray(state.categories.categories)){
            categories = state.categories.categories.filter(c=>{
                if(!c.sub_categories) return false;
                let subHasProducts = false;
                c.sub_categories.forEach(s=>{
                    if(sub_categories.includes(s)){
                        subHasProducts = true
                    }
                })
                return subHasProducts;
                // category.sub_categories && category.sub_categories.includes(item.sub_category)
                // const filterResult = c.product_count && c.product_count > 3
                // return filterResult
            }).sort(sortCategories)

            // categories = state.categories.categories.sort(sortCategories)
            
            /*
            categories = state.categories.categories.filter(c=>{
                const filterResult = c.product_count && c.product_count > 3
                return filterResult
            }).sort(sortCategories)
            */
        }
        // loading = state.categories.loading;
        // loaded = state.categories.loaded;
        // error = state.categories.error
    }




/*
    const categories = state.categories.categories.filter(c=>{
        const filterResult = c.product_count && c.product_count > 3
        return filterResult
    }).sort(sortCategories)
    */
    return {
        categories,
        showSideBar: state.settings.showSideBar
    }
}


export default connect(mapStateToProps, { toggleSidebar })(SideBar);