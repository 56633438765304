import { FILTERS } from '../actions';

const filters = (filters=[], action) => {
    switch(action.type){
        case FILTERS.toggle:
            if(!filters.includes(action.payload.filter)){
                return [
                    ...filters,
                    action.payload.filter
                ]
            }
            return filters.filter(item => item !== action.payload.filter);
        default:
            return filters
    }
}

const reducer = (state={}, action) => {
    switch(action.type) {
        case FILTERS.toggle:
            if(action.payload && action.payload.category && action.payload.filter){
                return {
                    ...state,
                    [action.payload.category]: filters(state[action.payload.category], action)
                }
            }
            return state;
        default:
            return state;
    }
}

export default reducer;