import React from 'react';
import { Grid, LinearProgress, Typography } from '@material-ui/core';

const LoadingProducts = () => {
    const year = new Date().getFullYear();
    return (
        <Grid container justify='center'>
            <Grid item>
                <Typography variant='h3'>Getting Our Favorite Products of {year}</Typography>
                <LinearProgress />
            </Grid>
        </Grid>
    )
}

export default LoadingProducts
