import axios from 'axios';

export const GET_STORES = {
    request: 'GET_STORES_REQUEST',
    success: 'GET_STORES_SUCCESS',
    failure: 'GET_STORES_FAILURE'
}

export const getStores = () => dispatch => {
    dispatch({ type: GET_STORES.request });
    axios.get("https://us-central1-clothes-shoes-and-toys.cloudfunctions.net/getStores")
        .then(response => {
            dispatch({
                type: GET_STORES.success,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: GET_STORES.failure,
                error
            })
        });
}
