import {
    LOGIN,
    LOGOUT,
    VERIFY_USER,
    VERIFY_EMAIL,
    CLEAR_AUTH_ERRORS,
    SIGNUP,
    SET_USER
} from "../actions";

export default (
    state = {
        isSigningUp: false,
        signedUp: false,
        signupError: null,

        emailVerificationSent: false,
        emailVerificationError: null,

        isLoggingIn: false,
        isLoggingOut: false,
        isVerifying: false,
        loginError: false,
        loginErrorDetails: null,
        logoutError: false,
        user: null
    },
    action
) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.user
            }
        case CLEAR_AUTH_ERRORS:
            return {
                ...state,
                loginError: false,
                loginErrorDetails: null,
                logoutError: false,
                signupError: null,
                signedUp: false,
                emailVericationSent: false,
                emailVerificationError: null
            };
        case SIGNUP.request:
            return {
                ...state,
                isSigningUp: true,
                signupError: null
            };
        case SIGNUP.success:
            return {
                ...state,
                isSigningUp: false,
                signedUp: true,
                signupError: null
        };
        case SIGNUP.failure:
            return {
                ...state,
                isSigningUp: false,
                signupError: action.error
            };
        case VERIFY_EMAIL.request:
            return {
                ...state,
                emailVericationSent: false,
                emailVerificationError: null
            };
        case VERIFY_EMAIL.success:
            return {
                ...state,
                emailVericationSent: true,
                emailVerificationError: null
            };
        case VERIFY_EMAIL.failure:
            return {
                ...state,
                emailVericationSent: false,
                emailVerificationError: action.error
            };
        case LOGIN.request:
            return {
                ...state,
                isLoggingIn: true,
                loginError: false
            };
        case LOGIN.success:
            return {
                ...state,
                isLoggingIn: false,
                user: action.user
            };
        case LOGIN.failure:
            return {
                ...state,
                isLoggingIn: false,
                loginError: true,
                loginErrorDetails: action.error
            };
        case LOGOUT.request:
            return {
                ...state,
                isLoggingOut: true,
                logoutError: false
            };
        case LOGOUT.success:
            return {
                ...state,
                isLoggingOut: false,
                user: null
            };
        case LOGOUT.failure:
            return {
                ...state,
                isLoggingOut: false,
                logoutError: true
            };
        case VERIFY_USER.request:
            return {
                ...state,
                isVerifying: true,
                verifyingError: false
            };
        case VERIFY_USER.success:
            return {
                ...state,
                isVerifying: false
            };
        default:
            return state;
    }
};
