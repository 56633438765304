import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form";

import firebase from '../../firebase';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Copyright from '../Copyright';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const ForgotPassword = () => {
    const classes = useStyles();
    const [formStatus, setFormStatus] = useState('PRISTINE');

    const { register, handleSubmit, errors, reset } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    });

    const onSubmit = data => {
        setFormStatus('REQUESTING');
        firebase
        .auth()
        .sendPasswordResetEmail(data.email)
        .then( () => { // does not send a response
            setFormStatus('SUCCESS')
            reset({email:''})
        })
        .catch(error => {
            setFormStatus('ERROR')
        });
    }

    const formFieldFocused = () => {
        if (formStatus !== 'PRISTINE') {
            setFormStatus('PRISTINE')
        }
    }

    const submitButtonText = formStatus === 'REQUESTING' ? "SENDING..." : "SEND RECOVERY EMAIL"
    const successMessage = formStatus === 'SUCCESS' ? <p style={{color:'green'}}>A password recovery email has been sent.</p> : null;
    const errorMessage = formStatus === 'ERROR' ? <p style={{color:'red'}}>Failed to send a password recovery email. Please make sure you entered the correct email, or signup for a new account</p> : null;

    return (
        <>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">Forgot Password</Typography>
            <p>Enter your email and we will send you a password reset link.</p>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form} noValidate>
                <TextField
                    variant="outlined"
                    margin="normal"
                    onFocus={formFieldFocused}
                    inputRef={register({
                        required: "Email is required.",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address."
                        }
                    })}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={errors.email ? true : false}
                    helperText={errors.email ? errors.email.message : null}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={formStatus !== 'PRISTINE'}
                    color="primary"
                    className={classes.submit}
                >{submitButtonText}</Button>

                {successMessage}
                {errorMessage}

                <Grid container>
                    <Grid item>
                        <Link to="/auth/login">
                            Return to Login
                        </Link>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </form>
        </>
    );
}

export default ForgotPassword;
