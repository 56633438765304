import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteIconOutlined from '@material-ui/icons/FavoriteBorderOutlined';


import { log } from '../../firebase'

const GoToFavorites = props => {
    const { categoryName, origin } = props
    if(origin && origin === 'header'){
        return (
            <Link onClick={() => log('favorites_clicked', { origin })} to='/favorites' style={{ textAlign: 'center', textDecoration: 'none' }}>
                <FavoriteIconOutlined style={{ 
                    textAlign: 'center', 
                    verticalAlign:'bottom', 
                    // fill: 'black' 
                    fontSize: '2rem',
                    color:'black'
                }} />
            </Link>
        )
    }

    return (
        <Link onClick={() => log('favorites_clicked', { categoryName })} to='/favorites' style={{ textDecoration: 'none' }}>
            <Button variant='outlined'>
                <Grid container alignItems="center">
                    <FavoriteIcon style={{ fill: 'rgba(239,204,225,1.0)', marginRight: '.5rem' }} />
                    <Typography variant='body2'>Favorites</Typography>
                </Grid>
            </Button>
        </Link>
    )
}

export default GoToFavorites