import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';
import { Grid, Card, Paper } from '@material-ui/core';

import { log } from '../../firebase'

import withWidth from '@material-ui/core/withWidth';

import FavoriteButton from './FavoriteButton';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '.25rem',
        /*
        [theme.breakpoints.up('md')]: {
            padding: '1rem',
        }
        */
    },
    storeContainer: { 
        padding: '.5rem',
        [theme.breakpoints.up('sm')]: {
            display: "flex", 
            justifyContent: "space-between", 
            alignItems: "center", 
            padding: "1rem"
        }
    },
    price: {
        fontWeight:'100',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem'
        },
    },
    productName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 1rem',
        height: '75px', /* Just enough to show 2 lines */
        fontWeight: '100'
    },
    imageContainer: {
        textAlign: 'center',
        padding: '1rem 1rem .5rem 1rem',
        [theme.breakpoints.down('xs')]: {
            height: '120px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '160px',
        },
        [theme.breakpoints.up('md')]: {
            height: '200px',
        },
    }
}));

const ProductCard = props => {
    const classes = useStyles();
    const { id, productName, imageLink, link, store, price, sub_category, description } = props.product;

    var maxLength = 50;
    switch (props.width) {
        case 'lg':
            maxLength = 75;
            break;
        case 'xl':
            maxLength = 100;
            break;
        default:
            break;
    }

    var productDisplayName = productName;
    if (productDisplayName.length > maxLength) {
        productDisplayName = productDisplayName.substring(0, 60);
        var n = productDisplayName.lastIndexOf(" ");
        productDisplayName = productDisplayName.substring(0, n)
        productDisplayName += " ..."
    }

    return (
        <Grid key={id} item xs={6} sm={4} md={3} lg={3} xl={3} className={classes.root}>
            <Card variant="outlined">
                <CardMedia style={{ borderBottom: '1px solid rgba(236, 236, 236, 1)', position: 'relative' }}>
                    <CardActions disableSpacing style={{ position: 'absolute', right: '0' }}>
                        <FavoriteButton productId={id} />
                    </CardActions>
                    <div className={classes.imageContainer}>
                        <a onClick={() => log('product_image_clicked', {store, price, productName, id, link, sub_category})} style={{ textDecoration: 'none', color: "inherit" }} href={link} target="_blank" rel="noopener noreferrer" >
                            <img src={imageLink} alt={productDisplayName} style={{ maxHeight: '100%', maxWidth: '100%', width: 'auto', height: 'auto' }} />
                        </a>
                    </div>
                    <div >
                        <Typography variant="body1" component="h1" noWrap={false} className={classes.productName}>
                            {productDisplayName}
                        </Typography>
                    </div>
                </CardMedia>
                <CardActionArea>
                    <a onClick={() => log('product_store_clicked', {store, price, productName, id, link, sub_category})} style={{ textDecoration: 'none', color: "inherit" }} href={link} target="_blank" rel="noopener noreferrer" >
                        <div className={classes.storeContainer}>
                            <Typography style={{ color: 'rgba(26,146,171,1.0)', fontWeight:'400' }} variant="body1" component="h1">
                                {store}
                            </Typography>
                            <Typography className={classes.price} variant="h6" component="p">
                                ${price}
                            </Typography>
                        </div>
                    </a>
                </CardActionArea>
            </Card>
        </Grid>
    );
}

export default withWidth()(ProductCard)

/*
<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 1rem" }}>
    <Typography style={{ float: "right" }} variant="body1" component="p">
        ${price}
    </Typography>
    <CardActions disableSpacing>
        <FavoriteButton productId={id} />
    </CardActions>
</div>
*/

/*
<CardActionArea>
    <CardActions disableSpacing style={{position:'absolute',right:'0'}}>
        <FavoriteButton productId={id} />
    </CardActions>
    <div className={classes.imageContainer}>
        <img src={imageLink} alt={productDisplayName} style={{ maxHeight: '100%', maxWidth: '100%', width: 'auto', height: 'auto' }} />
    </div>
    <div >
        <Typography variant="body1" component="h1" noWrap={false} className={classes.productName}>
            {productDisplayName}
        </Typography>
    </div>
</CardActionArea>
*/

/*
<CardMedia
    component="img"
    alt={productName}
    // height="140"
    style={{height:'300px'}}
    image={imageLink}
    title={productName}
/>
<CardContent>
    <Typography variant="h6" component="h1" noWrap={true}>
    {productName}
    </Typography>
    <Typography variant="body1" component="h2" style={{padding:".5rem 0 .5rem 0"}}>
    {store}
    </Typography>
    {descriptionSection()}
</CardContent>
*/