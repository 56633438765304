import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { log } from '../firebase';

const darkBackground = 'rgba(101,110,121,1)'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        backgroundColor: darkBackground,
        color: 'white',
        margin: '1.25rem',
        // border: '5px solid lightgrey',
        [theme.breakpoints.down('sm')]: {
            margin: '.75rem'
        },
        maxWidth:'1200px'
    },
    headline: {
        fontSize: '3rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '3rem'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '4rem'
        },
    },
    message: {
        fontSize: '2rem',
        fontWeight: '100',
        margin: '1.25rem 0',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem',
            margin: '2rem 0',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '2rem'
        },
    },
    imageBox: {
        order: 1,
        /*
        [theme.breakpoints.down('sm')]: {
            order: 1
        },
        */
        [theme.breakpoints.up('sm')]: {
            order: 2
        }
    },
    image: { 
        // height: '30vh',
        maxWidth: '80%',
        verticalAlign:'middle',
        textAlign: 'center',
        marginTop:'1rem',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            maxWidth: '100%',
            marginTop:'0'
        }
    },
    textBox: {
        padding: '2rem',
        order: 1,
        textAlign: 'left',
        /*
        [theme.breakpoints.up('sm')]: {
            // order: 1,
            // textAlign: 'left'
        },
        [theme.breakpoints.up('md')]: {
            // order: 1,
            // textAlign: 'left'
        },
        */
        [theme.breakpoints.down('xs')]: {
            order: 2,
            textAlign: 'center'
        }
    }

}));

const BannerCta = props => {
    const { link, path, linkText } = props;
    // link will be external, path will be to a page
    if (link) {
        return <a onClick={() => log('banner_clicked', {link, linkText})} target="_blank" href={link}>{linkText}</a>
    }
    if (path) {
        return <Link onClick={() => log('banner_clicked', {path, linkText})} to={path}><Button style={{backgroundColor:'white'}}>{linkText}</Button></Link>
    }
    return null
}

const Banner = props => {
    const classes = useStyles();
    const { headline, message, image, imageDescription } = props;
    var imageURL = process.env.PUBLIC_URL + '/images/banners/' + image;
    return (
        <Grid container alignItems='center' className={classes.mainContainer}>
            <Box clone className={classes.textBox}>
                <Grid item xs={12} sm={7}>
                    <Typography className={classes.headline} variant="h2" component="h1">{headline}</Typography>
                    <Typography className={classes.message} variant="h5" component="p">{message}</Typography>
                    <BannerCta {...props} />
                </Grid>
            </Box>
            <Box clone className={classes.imageBox}>
                <Grid item xs={12} sm={5} style={{textAlign:'center'}} >
                    <img src={imageURL} alt={imageDescription} className={classes.image} />
                </Grid>
            </Box>

        </Grid>
    )
}

export default Banner