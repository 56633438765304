import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Card, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

import { log } from '../../firebase';

// import { getImageURL } from '../../actions';
// import PlacerHolderImage from '../../public/images/placeholder-image.png';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        // border: 'solid red 4px',
        // padding: theme.spacing(6),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    button: {
        // border: 'solid green 4px',
        width: '100%'
    },
    item: {
        padding: 0, //'.25rem',
        // border:'solid blue 1px'
    },
    categoryName: {
        padding: '.5rem 1rem',
        fontWeight: '100'
    }
}));

const CategoryItem = (props) => {
    const classes = useStyles();
    const { name, image, link } = props;
    // const { getImageURL } = props;

    // const { imageURL, imageLoading, imageLoaded, imageError } = props;

    // var officialImageURL = process.env.PUBLIC_URL + 'images/placeholder-image.png'
    // console.log("process.env.PUBLIC_URL: ", process.env.PUBLIC_URL)
    var officialImageURL = process.env.PUBLIC_URL + '/images/category/' + image;

    // Similar to componentDidMount and componentDidUpdate:
    /*
    useEffect(() => {
        if(!imageLoaded && !imageLoading) {
            getImageURL(image);
        }
    });
    */

    // console.log("image: ", image)

    /*
    if(imageError) {
        // we should probably do something here, but placeholder is fine
    } else if(imageURL) {
        officialImageURL = imageURL
    }
    */
   
    return (
        <Grid item xs={6} sm={4} md={3} lg={3} xl={2} className={classes.item}>
            <Link onClick={() => log('category_clicked', {link, name})} to={`/${link}`}>
                <Button className={classes.button}>
                    <Card className={classes.paper}>
                        <img alt={name} src={officialImageURL} style={{height:'auto',maxWidth:'100%'}} />
                        <Typography className={classes.categoryName} variant="body2" component="h1">{name}</Typography>
                    </Card>
                </Button>
            </Link>
        </Grid>
    )
}

function mapStateToProps(state, ownProps) {
    var imagePath = ownProps.image
    var imageURL = null;
    var imageLoading = false;
    var imageLoaded = false;
    var imageError = null;
    if (state && state.images && state.images[imagePath]) {
        imageURL = state.images[imagePath].imageURL;
        imageLoaded = state.images[imagePath].loaded;
        imageLoading = state.images[imagePath].loading;
        imageError = state.images[imagePath].error;
    }
    return {
        imageURL,
        imageLoading,
        imageLoaded,
        imageError
    }
}

export default connect(mapStateToProps, {})(CategoryItem);