import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, LinearProgress, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { log } from '../../firebase';

import { toggleFilter, getProducts } from '../../actions'

// import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ProductCard from './ProductCard';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    center: {
        textAlign: 'center'
    },
    filterActive: {
        border: 'solid 3px rgba(26,146,171,1.0)',
        color: 'rgba(26,146,171,1.0)',
        backgroundColor: 'rgba(26,146,171,0.2)',
        width: '6rem',
        height: '6rem',
        borderRadius: '50%',
        margin: '.5rem'
    },
    filter: {
        border: 'solid 3px grey',
        backgroundColor: 'rgba(26,146,171,0.0)',
        color: 'grey',
        width: '6rem',
        height: '6rem',
        borderRadius: '50%',
        margin: '.5rem'
    },
    filterContainer: {
        padding: '0 1rem 1rem 1rem'
    },
    breadCrumbContainer: {
        padding: '1rem'
    },
    productsContainer: {
        padding: '.25rem',
        backgroundColor: 'lightgrey'
    }
}));

const FavortiesPage = props => {
    const classes = useStyles();
    const { products, status, filters } = props;

    useEffect(() => {
        const { getProducts, status } = props;
        if (status === 'NONE') {
            getProducts()
        }
    }, []);

    /*
    const handleToggle = (filter) => {
        toggleFilter('favorites', filter)
    }
    */

    const filterList = () => {
        return null;
        /*
        if (products.length < 16) {
            return null;
        }
        return (
            <Grid container item xs={12} justify='center' className={classes.filterContainer}>
                {category.filters.map(filter => {
                    var style = classes.filter;
                    if (filters.includes(filter.name)) {
                        style = classes.filterActive
                    }
                    return (
                        <Grid key={filter.name} item>
                            <Button className={style} onClick={() => handleToggle(filter.name)}>
                                {filter.name}
                            </Button>
                        </Grid>
                    )
                })
                }
            </Grid>
        )
        */
    }

    const productsList = () => {
        if (status === 'NONE' || status === 'REQUESTING') {
            return (
                <Grid container justify='center'>
                    <Grid item>
                        <LinearProgress />
                    </Grid>
                </Grid>
            )
        }
        const list = products.map(product => {
            if (filters.length > 0) {
                var showProduct = product.filters.some(item => filters.includes(item));
                if (!showProduct) return null;
            }
            return <ProductCard key={product.link} product={product} />
        })
        if (list < 1) {
            return <Typography>You have not added any favorites yet.</Typography>
        }
        return list
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid container item xs={12} alignItems='center' className={classes.breadCrumbContainer}>
                    <Link onClick={() => log('back_to_all', {referring_page:'favorites'})} to='/' style={{ textDecoration: 'none' }}>
                        <Grid container alignItems="center">
                            <Typography variant='body2'>All Products</Typography>
                        </Grid>
                    </Link>
                    <span style={{ padding: '0 .5rem' }}>/</span>
                    <Typography variant='body2'>Favorites</Typography>
                </Grid>
                {filterList()}

                <Grid container item xs={12} justify='flex-start' className={classes.productsContainer}>
                    {productsList()}
                </Grid>
            </Grid>
        </div>
    );
}

function mapStateToProps(state, ownProps) {

    /*
    const categoryLink = ownProps.match.params.category;
    var filters = []
    if (state.filters && state.filters[categoryLink]) {
        filters = state.filters[categoryLink]
    }
    */

   var filters = []
   if (state.filters && state.filters['favorites']) {
       filters = state.filters['favorites']
   }

    var products = [];
    if (state.products.products && Array.isArray(state.products.products)) {
        products = state.products.products.filter(item => {
            return state.favorites.favorites.includes(item.id)
            // return item.category.includes(categoryLink)
        })
    }

    return {
        categories: state.categories.categories,
        favorites: state.favorites,
        filters,
        products,
        status: state.products.status
    }
}

export default connect(mapStateToProps, { toggleFilter, getProducts })(FavortiesPage);

//  <Grid container spacing={2} justify='center' alignItems='flex-start'>