import { ADD_FAVORITE, REMOVE_FAVORITE, GET_FAVORITES_LS } from '../actions'

const initialState = {
    loaded: false,
    loading: false,
    errorLoad: null,
    lastLoaded: null,
    favorites: [],
    errorAdd: {},
    errorRemove: {}
}

// add favorite to array on add request
// if add has error, remove from array and add error to addErrors array
// follow same logic for remove

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FAVORITES_LS.success:
            return {
                ...state,
                favorites: [
                    ...state.favorites,
                    ...action.favorites
                ]
            }
        case ADD_FAVORITE.request:
            const newErrorAdd = {...state.errorAdd}
            delete newErrorAdd[action.id];
            return {
                ...state,
                errorAdd: {...newErrorAdd},
                favorites: [
                    ...state.favorites,
                    action.id
                ]
            };
            case REMOVE_FAVORITE.request:
                const newErrorRemove = {...state.errorRemove};
                delete newErrorRemove[action.id];
                return {
                    ...state,
                    errorRemove: {...newErrorRemove},
                    favorites: [
                        ...state.favorites.filter(f=>f!==action.id)
                    ]
                };
        default:
            return state;
    }
}

export default reducer;
