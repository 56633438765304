import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import { toggleFilter, getProducts } from '../../actions'

import Design from '../Design';

import SubHeader from './SubHeader';

import ProductCard from './ProductCard';
import LoadingProducts from './LoadingProducts';

import { Link } from 'react-router-dom';
import { log } from '../../firebase'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: '80vh'
    },
    center: {
        textAlign: 'center'
    },
    filterActive: {
        border: 'solid 1px rgba(26,146,171,1.0)',
        color: 'rgba(26,146,171,1.0)',
        backgroundColor: 'rgba(26,146,171,0.2)',
        // width: '6rem',
        // height: '6rem',
        borderRadius: '.5rem', // '50%',
        margin: '.25rem'
    },
    filter: {
        border: 'solid 1px grey',
        backgroundColor: 'rgba(26,146,171,0.0)',
        color: 'grey',
        borderRadius: '.5rem', // '50%',
        margin: '.25rem'
    },
    homeButton: {
        border: `solid 1px ${Design.pink}`,
        backgroundColor: 'rgba(26,146,171,0.0)',
        color: Design.pink,
        borderRadius: '.5rem', // '50%',
        margin: '.25rem'
    },
    filterContainer: {
        padding: '.25rem' // '0 1rem 1rem 1rem'
    },
    breadCrumbContainer: {
        padding: '1rem'
    },
    productsContainer: {
        padding: '.5rem',
        [theme.breakpoints.up('sm')]: {
            padding: '.5rem 1rem',
        },
        [theme.breakpoints.up('md')]: {
            padding: '1rem 3rem',
        },
        backgroundColor: 'rgba(236, 236, 236, 1)'
    }
}));

const NewProductPage = props => {
    const classes = useStyles();
    const { category, products, status, getProducts, productsSplitBySub, loading } = props;

    const [filter, setFilter] = React.useState('ALL');

    useEffect(() => {
        if (status === 'NONE') {
            getProducts()
        }
    }, []);

    const goBack = () => {
        return (
            <Grid key='BackHome' item>
                <Button className={classes.homeButton}
                    onClick={() => {
                        log('back_to_all_clicked')
                        props.history.push("/")
                    }
                    }>
                        <ArrowLeftIcon />
                    Home Page
                    </Button>
            </Grid>
        )
    }

    const filterArray = () => {
        return productsSplitBySub.map(sub => {
            var style = classes.filter;
            if (filter === sub.link) {
                style = classes.filterActive
            }
            return (
                <Grid key={sub.name} item>
                    <Button className={style} onClick={() => setFilter(sub.link)}>
                        {sub.name}
                    </Button>
                </Grid>
            )
        })

    }

    const filterList = () => {
        // const list = [{ name: "All", link: 'ALL' }, ...productsSplitBySub]
        return (
            <Grid container item xs={12} justify='center' className={classes.filterContainer}>
                {[goBack(), filterArray()]}
            </Grid>
        )
    }

    const justProducts = products => {
        if (products && Array.isArray(products) && products.length > 0) {
            return products.map(product => <ProductCard key={product.link} product={product} />)
        }
        return <Typography variant="body" component="p">We are currently updating these products. Check back soon for great products!</Typography>
    }

    const productList = () => {
        const list = filter === 'ALL' ? productsSplitBySub : [productsSplitBySub.find(sub => sub.link === filter)]
        return list.map(thisSub => {
            return (
                <Grid container key={thisSub.name}>
                    {false && <Grid item xs={12} style={{ margin: '1rem .5rem' }}>
                        <Typography variant="h3" component="h1">{thisSub.name}</Typography>
                    </Grid>}
                    <Grid item xs={12} container style={{ marginBottom: '2rem' }}>
                        {justProducts(thisSub.products)}
                    </Grid>
                </Grid>
            )
        })

    }

    const productsToDisplay = () => {
        if (loading) {
            return <LoadingProducts />
        }
        if (products.length < 1) {
            return <Typography>We are currently updating this page with more products, check back soon!</Typography>
        }
        return productList()
    }

    return (
        <div className={classes.root}>
            <Grid container>
                {false && <SubHeader categoryName={category.name} />}
                {filterList()}
                <Grid container item xs={12} justify='center' className={classes.productsContainer}>
                    {productsToDisplay()}
                </Grid>
            </Grid>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    const categoryLink = ownProps.match.params.category;

    var filters = []
    if (state.filters && state.filters[categoryLink]) {
        filters = state.filters[categoryLink]
    }

    const categories = state.categories.categories;
    const subcategories = state.subcategories.subcategories

    var category = {}
    for (var c of categories) {
        if (c.link === categoryLink) {
            category = c
        }
    }

    var productsSplitBySub = []

    var products = [];
    if (state.products.products && Array.isArray(state.products.products) && category.sub_categories && Array.isArray(category.sub_categories)) {
        products = state.products.products.filter(item => {
            const include = category.sub_categories.includes(item.sub_category)
            if (include) {
                const daitem = productsSplitBySub.find(sub => sub.link === item.sub_category)
                if (!daitem) {
                    var subcat = subcategories.find(s => s.link === item.sub_category)
                    if (!subcat) {
                        console.log("could not find ", item.sub_category)
                        subcat = { name: 'Other Great Products' }
                    }
                    productsSplitBySub.push({
                        link: item.sub_category,
                        name: subcat.name,
                        products: [item]
                    })
                } else {
                    daitem.products.push(item)
                }
            }
            return include
        })
    }

    return {
        category,
        categories,
        favorites: state.favorites,
        filters,
        products,
        productsSplitBySub,
        loading: state.categories.loading || state.subcategories.loading || state.products.status === 'NONE' || state.products.status === 'REQUESTING',
        status: state.products.status,
        subcategories
    }
}

export default connect(mapStateToProps, { toggleFilter, getProducts })(NewProductPage);

// {false && <Typography style={{padding:'.5rem',textAlign:'center'}} variant="body2" component="body">Mommy Favorites may earn a commission when you use the affiliate links on our site to make a purchase.<br />*Prices are last seen and may differ from current price.</Typography>}