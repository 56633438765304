import { SETTINGS } from '../actions';

const settings = {
    showSideBar: false
}

const reducer = (state=settings, action) => {
    switch(action.type) {
        case SETTINGS.toggleSidebar:
            return {
                ...state,
                showSideBar: !state.showSideBar
            }
        default:
            return state;
    }
}

export default reducer;