// suggested by Tyler
// pink: 'rgba(215,156,160,1.0)'
// grey: 'rgba(101,110,121,1.0)'
// lavendar: 'rgba(213,203,213,1.0)'
// green: 'rgba(177,211,202,1.0)'


const Design = {
    mainColor: 'rgba(239,204,225,1.0)', // 'rgba(255,55,82,1.0)' // 'rgba(26,146,171,1.0)'
    pink: 'rgba(215,156,160,1.0)',
    darkgrey: 'rgba(101,110,121,1.0)',
    lavendar: 'rgba(213,203,213,1.0)',
    green: 'rgba(177,211,202,1.0)'
}

export default Design;