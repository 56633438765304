import React from 'react';
import { connect } from 'react-redux';
import Design from './Design';
import { Link } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

import { Button, Grid } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import GoToFavorites from './Partials/GoToFavorites';

import { logoutUser, toggleSidebar } from '../actions';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    link: {
        margin: theme.spacing(1, 1.5),
        color: 'white',
        borderColor: 'white'
    },
    spacer: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

// export default function SearchAppBar() {
const SearchAppBar = props => {

    const { toggleSidebar, auth, logoutUser } = props;

    const classes = useStyles();

    const loginButton = () => {
        if (auth.user) {
            return (
                <Button
                    // href="#" 
                    // color="primary"
                    variant="outlined"
                    className={classes.link}
                    onClick={() => logoutUser()}
                >Logout</Button>
            )
        } else {
            return (
                <Link to="/auth/login" style={{ textDecoration: 'none' }}>
                    <Button
                        // href="#" 
                        // color="primary"
                        variant="outlined"
                        className={classes.link}
                    >Login</Button>
                </Link>
            )
        }
    }

    const style = {
        backgroundColor: Design.mainColor
    };

    const toggleDrawer = (text) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        toggleSidebar(text)
    };

    return (
            <AppBar position="sticky" style={style}>
                <Toolbar>
                    <Grid container>
                        <Grid item container xs={4} alignItems='center' alignContent='center' justifyContent='center'>
                            <IconButton
                                onClick={toggleDrawer('menu')}
                                edge="start"
                                className={classes.menuButton}
                                color="black" // "inherit"
                                aria-label="open drawer"
                            >
                                <MenuIcon />
                            </IconButton>
                            <GoToFavorites origin='header' />
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <img
                                src={process.env.PUBLIC_URL + '/images/site/mommy-favorites-logo2.png'}
                                alt="mommy favorites logo"
                                style={{ height: '3rem' }}
                            />
                            </Link>
                        </Grid>
                        <Grid item xs={4}>

                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
    );
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        showSideBar: state.settings.showSideBar
    }
}

export default connect(mapStateToProps, { toggleSidebar, logoutUser })(SearchAppBar);

// <div className={classes.root}>

/*
<Toolbar justifyContent="center">
                    <IconButton
                        onClick={toggleDrawer('menu')}
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                    >
                        <MenuIcon />
                    </IconButton>

                    <div className={classes.spacer}></div>
                    <img
                        src={process.env.PUBLIC_URL + '/images/site/mommy-favorites-logo.png'}
                        alt="mommy favorites logo"
                        style={{height:'3rem'}}
                    />
                    <div className={classes.spacer}></div>

                    {false && loginButton()}
                </Toolbar>
                */

/*
<Typography className={classes.title} variant="h6" noWrap>
    Mommy Favorites
</Typography>
*/

/*
<div className={classes.search}>
    <div className={classes.searchIcon}>
        <SearchIcon />
    </div>
    <InputBase
        placeholder="Search…"
        classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
    />
</div>
*/

/*
<AccountCircleIcon />
<Fab color="secondary" aria-label="add">
    <AccountCircleIcon />
</Fab>
*/

/*
<div>
    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
    Open Menu
    </Button>
    <Menu
    id="simple-menu"
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={handleClose}
    >
    <MenuItem onClick={handleClose}>Profile</MenuItem>
    <MenuItem onClick={handleClose}>My account</MenuItem>
    <MenuItem onClick={handleClose}>Logout</MenuItem>
    </Menu>
</div>
*/