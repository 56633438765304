import React, { useEffect } from 'react';
import { log } from '../../firebase';

const Privacy = () => {
    useEffect(() => {
        var s = document.createElement("script");
        let tag = document.getElementsByTagName("script")[0];

        s.src="https://cdn.iubenda.com/iubenda.js";

        tag.parentNode.insertBefore(s,tag);
    }, []);

    return <a onClick={() => log('privacy_clicked')} href={`https://www.iubenda.com/privacy-policy/28183052`} className="iubenda-white no-brand iubenda-embed" title="Privacy Policy ">Privacy Policy</a>

}

export default Privacy;

/*
<a href="https://www.iubenda.com/privacy-policy/28183052" className="iubenda-white no-brand iubenda-embed" title="Privacy Policy ">Privacy Policy</a>
        <script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
        */