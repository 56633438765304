import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Privacy from './Partials/Privacy';
import Terms from './Partials/Terms';

const Footer = () => {
    
    return (
        <Grid container style={{ padding: '2rem', backgroundColor: 'rgba(101,110,121,1)', color: 'white', textAlign: 'center' }}>
            <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" align="center" style={{ color: 'white' }}>
                    Mommy Favorites may earn a commission when you use the affiliate links on our site to make a purchase.<br />There is no additional cost to you.<br />*Prices are last seen and may differ from current price.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" align="center" style={{ color: 'white', padding: '1rem' }}>
                <Privacy /> <Terms />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" align="center" style={{ color: 'white' }}>
                    {'Copyright © MommyFavorites.com'}
                    {' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Footer;

// const privacyURL = 'https://app.termly.io/document/privacy-policy/25fee71b-d774-4d67-913e-8f03663e0d53'
// const termsURL = 'https://app.termly.io/document/terms-and-conditions/d3322366-d10e-4278-9507-ef9f6a2e8f20'

// <a href={privacyURL}>Privacy Policy</a>
// <a href={termsURL}>Terms of Service</a>