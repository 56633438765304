import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import thunk from 'redux-thunk';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import './App.css';
import reducers from './reducers';

import Main from './components/Main'
import ScrollToTop from './components/ScrollToTop';

const theme = createMuiTheme();

theme.typography.fontFamily = [
    // 'Yeseva One',
    // 'Nunito',
    // 'Lato',
    // 'Roboto',
    'Montserrat',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif'
].join(',')

theme.typography.h3 = {
    ...theme.typography.h3,
    [theme.breakpoints.down('lg')]: {
        fontSize: '2rem'
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: '1.5rem'
    }
}

const store = createStore(reducers, applyMiddleware(thunk));

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <BrowserRouter>
                    <ScrollToTop />
                    <Main />
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    );
}

export default App;