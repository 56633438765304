import React from 'react';
import { connect } from 'react-redux';

import CategoryItem from './CategoryItem';
import { Typography } from '@material-ui/core';

import { sortCategories } from '../../common'

const CategoryList = props => {

    const { loading, loaded, error, categories } = props;

    if(error){
        return <Typography>Ooops, there was an error :(</Typography>
    }

    if(!loaded || loading){
        return <Typography>Loading...</Typography>
    }

    return (
        categories.map(item => {
            return <CategoryItem key={item.link} link={item.link} name={item.name} image={item.image} />
        })
    );
}

function mapStateToProps(state) {
    var categories = [];
    var loading = false;
    var loaded = false;
    var error = null;

    let products = []
    let sub_categories = []

    if (state.products.products && Array.isArray(state.products.products)) {
        products = state.products.products
    }

    products.forEach(p=>{
        if(p.sub_category){
            if(!sub_categories.includes(p.sub_category)){
                sub_categories.push(p.sub_category)
            }
        }
    })

    if (state && state.categories) {
        if(state.categories.categories && Array.isArray(state.categories.categories)){
            categories = state.categories.categories.filter(c=>{
                if(!c.sub_categories) return false;
                let subHasProducts = false;
                c.sub_categories.forEach(s=>{
                    if(sub_categories.includes(s)){
                        subHasProducts = true
                    }
                })
                return subHasProducts;
                // category.sub_categories && category.sub_categories.includes(item.sub_category)
                // const filterResult = c.product_count && c.product_count > 3
                // return filterResult
            }).sort(sortCategories)

            // categories = state.categories.categories.sort(sortCategories)
            
            /*
            categories = state.categories.categories.filter(c=>{
                const filterResult = c.product_count && c.product_count > 3
                return filterResult
            }).sort(sortCategories)
            */
        }
        loading = state.categories.loading;
        loaded = state.categories.loaded;
        error = state.categories.error
    }
    return {
        categories,
        loading,
        loaded,
        error
    }
}

export default connect(mapStateToProps,{})(CategoryList);
