import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Design from '../Design';
import Copyright from '../Copyright';

import { signupUser, clearAuthErrors } from '../../actions';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: Design.mainColor // theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: Design.mainColor,
        color: 'white'
    },
}));

const SignUp = props => {
    const classes = useStyles();
    const { signupUser, auth, clearAuthErrors } = props;
    const { register, handleSubmit, watch, errors, control } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    });

    useEffect(() => {
        // Specify how to clean up after this effect:
        return () => {
            clearAuthErrors()
        };
      }, [] ); // use empty array as 2nd argument to make sure this only fires once and not on re-render

    const onSubmit = data => {
        signupUser(data.email, data.password)
    }

    const formFieldFocused = () => {
        if(auth.signupError || auth.emailVerificationError){
            // clearAuthErrors();
        }
    }

    return (
        <>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign up
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            onFocus={formFieldFocused}
                            inputRef={register({
                                required: "Email is required.",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Invalid email address."
                                }
                            })}
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            error={errors.email ? true : false}
                            helperText={errors.email ? errors.email.message : null}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onFocus={formFieldFocused}
                            inputRef={register({
                                required: "Password is required.",
                                pattern: {
                                    value: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/i,
                                    message: "Password should be at least 8 characters and contain at least one letter and at least one number."
                                }
                            })}
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={errors.password ? true : false}
                            helperText={errors.password ? errors.password.message : null}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox value="allowExtraEmails" color="primary" />}
                            label="I want to receive inspiration, marketing promotions and updates via email."
                        />
                    </Grid>
                </Grid>

                {auth.signupError ? <p style={{color:'red'}}>{auth.signupError.message}</p> : null}
                {auth.emailVerificationError ? <p style={{color:'red'}}>{auth.emailVerificationError.message}</p> : null}

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                >SIGN UP</Button>
                
                <Grid container justify="flex-end">
                    <Grid item>
                        <Link to='/auth/login' className="auth-links">
                            Already have an account? Login
                        </Link>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </form>
        </>
    );
}


function mapStateToProps(state) {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { signupUser, clearAuthErrors })(SignUp);

/*
<Grid item xs={12} sm={6}>
    <TextField
        onFocus={formFieldFocused}
        inputRef={register({
            required: "First Name is required.",
            pattern: {
                value: /^[A-Za-z\s0-9]{2,30}$/i,
                message: "No special characters allowed"
            }
        })}
        autoComplete="fname"
        name="firstName"
        variant="outlined"
        required
        fullWidth
        id="firstName"
        label="First Name"
        autoFocus
        error={errors.firstName ? true : false}
        helperText={errors.firstName ? errors.firstName.message : null}
    />
</Grid>
<Grid item xs={12} sm={6}>
    <TextField
        onFocus={formFieldFocused}
        inputRef={register({
            required: "Last Name is required.",
            pattern: {
                value: /^[A-Za-z\s0-9]{2,30}$/i,
                message: "No special characters allowed"
            }
        })}
        variant="outlined"
        required
        fullWidth
        id="lastName"
        label="Last Name"
        name="lastName"
        autoComplete="lname"
        error={errors.lastName ? true : false}
        helperText={errors.lastName ? errors.lastName.message : null}
    />
</Grid>
*/