import axios from 'axios';

export const GET_PRODUCTS = {
    request: 'GET_PRODUCTS_REQUEST',
    success: 'GET_PRODUCTS_SUCCESS',
    failure: 'GET_PRODUCTS_FAILURE'
}

export const getProducts = () => dispatch => {
    dispatch({ type: GET_PRODUCTS.request });
    axios.get("https://us-central1-clothes-shoes-and-toys.cloudfunctions.net/getProducts")
        .then(response => {
            dispatch({
                type: GET_PRODUCTS.success,
                products: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: GET_PRODUCTS.failure,
                error
            })
        });
}
