import {
    GET_IMAGE_URL
} from '../actions';

const reducer = (state={}, action) => {
    switch(action.type) {
        case GET_IMAGE_URL.request:
            return {
                ...state,
                [action.path]: {
                    loading: true,
                    loaded: false,
                    timeLoaded: null,
                    imageURL: null,
                    error: null
                }
            }
        case GET_IMAGE_URL.success:
            return {
                ...state,
                [action.path]: {
                    loading: false,
                    loaded: true,
                    timeLoaded: Date.now(),
                    error: null,
                    imageURL: action.imageURL
                }
            }
        case GET_IMAGE_URL.failure:
            return {
                ...state,
                [action.path]: {
                    loading: false,
                    loaded: false,
                    error: action.error
                }
            }
        default:
            return state;
    }
}

export default reducer;