import { GET_CATEGORIES } from '../actions';

const initialState = {
    loading: false,
    loaded: false,
    timeLoaded: null,
    error: null,
    categories: []
}

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case GET_CATEGORIES.request:
            return {
                ...state,
                loading: true,
                error: null
            }
        case GET_CATEGORIES.success:
            return {
                ...state,
                loading: false,
                loaded: true,
                timeLoaded: Date.now(),
                error: null,
                categories: action.payload
            }
        case GET_CATEGORIES.failure:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}

export default reducer;