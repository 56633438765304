export const FILTERS = {
    toggle: 'TOGGLE_FILTER'
}

export const toggleFilter = (category, filter) => {
    return {
        type: FILTERS.toggle,
        payload: {category, filter}
    };
}
